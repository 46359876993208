* {
  box-sizing: border-box;
  font-family: "Roboto";
  margin: 0;
  padding: 0;
}

#map {
  height: 180px;
}

@keyframes glossy-animation {
  0% {
    transform: scale(1.1, 1.4);
    box-shadow: 0 0 1px #00796b;
  }
  25% {
    transform: scale(1);
    box-shadow: 0 0 25px #ffb300;
  }
  50% {
    transform: scale(1.1, 1.4);
    box-shadow: 0 0 1px #00796b;
  }
  75% {
    transform: scale(1);
    box-shadow: 0 0 25px #ffb300;
  }
  100% {
    transform: scale(1.1, 1.4);
    box-shadow: 0 0 1px #00796b;
  }
}
